<template>
  <ion-page>
    <Header back-to="/auth" />
    <ion-content :fullscreen="true">
      <div class="container">
        <div class="flex col-center">
          <h2 class="title">Lupa Password</h2>
          <div class="text-center text-sm">Masukkan akun email yang Anda gunakan dan Doo.id akan mengirimkan email untuk mereset password.</div>
        </div>
        <div class="form mt-2">
          <div class="input-group">
            <span class="before">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 6L12 13L2 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <ion-input class="input" placeholder="email@doo.id" v-model="email"></ion-input>
          </div>
          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="send">Kirim</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    Header
  },
  data () {
    return {
      email: null,
    }
  },
  methods: {
    send () {
      this.$api.post('/api/forgot-password', { email: this.email }).then(res => {
        if(! res.error)
          this.$router.push({name: 'ForgotPasswordLinkSendSuccess'})
      })
    }
  }
 })
</script>

<style>

</style>
